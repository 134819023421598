import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { Menu, Layout, Button, Alert, Space, Modal } from 'antd';
import { useSelector } from 'react-redux';
import LayoutHeader from './Header';
import Breadcrumb from './Breadcrumb';
import store from '@store';
import { readDisclaimers } from '@common/api/huoyunjie';
import {
  LayoutWrapper,
  LayoutInner,
  LayoutContent,
  LayoutSidebarAffix,
  SystemName,
} from './styled';

const { Sider } = Layout;
const { SubMenu, Item: MenuItem } = Menu;

const DefaultLayout = ({ children }) => {
  const { pathname } = useLocation();
  const menu = useSelector((state) => state.menu);
  const { disclaimersForce } = useSelector((state) => state.user);
  const [openKeys, setOpenKeys] = React.useState([]);
  const [selectedKeys, setSelectedKeys] = React.useState([]);

  const menuActiveStatus = (() => {
    const selectedKeys = [];
    const openKeys = [];

    menu.forEach((parent) => {
      parent.menu.forEach((child) => {
        const matched = matchPath(pathname, {
          path: child.url,
        });
        if (matched) {
          openKeys.push(parent.name);
          selectedKeys.push(child.url);
        }
      });
    });

    return {
      openKeys,
      selectedKeys,
    };
  })();
  React.useEffect(() => {
    setOpenKeys(menuActiveStatus.openKeys);
    setSelectedKeys(menuActiveStatus.selectedKeys);
  }, [pathname, menu]);

  React.useEffect(() => {
    if (disclaimersForce) {
      handleView();
    }
  }, [disclaimersForce]);

  const handleMenuOpenChange = (newOpenKeys) => {
    setOpenKeys([...newOpenKeys]);
  };

  const handleView = () => {
    Modal.info({
      title: '使用须知',
      className: 'disclaimers',
      content: (
        <div
          style={{
            whiteSpace: 'break-spaces',
          }}
        >
          {
            '货主方：\n1.请您核对并确认所发布的委托信息（如起止地址、货物信息、时间等）是否合法、真实、准确，托运相关要求是否已与承运人达成一致，并核实相关托运资质是否齐全、有效；\n2.本平台仅为信息共享平台，与您之间无代理关系，与您订单承运人间亦不存在劳动/劳务/委托等关系；\n3.委托订单成立前，请注意核实承运人发布/告知的相关信息，订单一旦成立，您与承运人间的包括但不限于合作、结算、纠纷等，均与本平台无关；\n4.请自觉遵守履行委托订单所涉相关法律法规、地方政策等规定。\n\n承运方：\n1.请您核对并确认所发布的承运信息（如车辆信息）是否合法、真实、准确，承运相关标准是否与托运人达成一致，并核实您承接该订单的资质是否齐全、有效；\n2.本平台仅为信息共享平台，与您之间不存在劳动/劳务/委托等关系，与托运人之间并不存在代理关系；\n3.委托订单成立前，请注意核实拖运人发布/告知的相关信息，一旦成立，您与拖运人间的包括但不限于合作、结算、纠纷等，均与本平台无关；\n4.请自觉遵守履行委托订单所涉相关法律法规、地方政策等规定。\n'
          }
        </div>
      ),
      okText: '我已知悉，不再提示',
      onOk() {
        readDisclaimers().then(async () => {
          await store.dispatch.user.updateDisclaimers(false);
          if (typeof disclaimersForce === 'function') {
            setTimeout(() => {
              disclaimersForce();
            }, 300);
          }
        });
      },
    });
  };

  return (
    <LayoutWrapper>
      <LayoutHeader />
      <LayoutInner>
        <LayoutSidebarAffix>
          <Sider theme="light">
            <SystemName>箱信</SystemName>
            <Menu
              mode="inline"
              openKeys={openKeys}
              selectedKeys={selectedKeys}
              onOpenChange={handleMenuOpenChange}
            >
              {menu.map((parent) => {
                return (
                  <SubMenu title={parent.name} key={parent.name}>
                    {parent.menu.map((child) => {
                      return (
                        <MenuItem key={child.url}>
                          <Link to={child.url}>{child.name}</Link>
                        </MenuItem>
                      );
                    })}
                  </SubMenu>
                );
              })}
            </Menu>
          </Sider>
        </LayoutSidebarAffix>
        <Layout>
          <Breadcrumb />
          <LayoutContent>
            {pathname.startsWith('/huoyunjie') ? (
              <div
                style={{
                  marginTop: '-15px',
                }}
              >
                <Alert
                  style={{
                    padding: '2px 8px',
                  }}
                  message="使用须知"
                  type="warning"
                  action={
                    <Space>
                      <Button
                        type="link"
                        size="small"
                        ghost
                        onClick={handleView}
                      >
                        点击查看
                      </Button>
                    </Space>
                  }
                />
              </div>
            ) : null}
            {children}
          </LayoutContent>
        </Layout>
      </LayoutInner>
    </LayoutWrapper>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node,
};

export default DefaultLayout;
