import { get } from 'lodash';
import api from '@common/api';
import { getDisclaimersStatus } from '@common/api/huoyunjie';
import { PlatformUtils } from 'xiangxin-tool';
const initialState = {
  enterpriseName: null,
  mobile: null,
  name: null,
  scope: null,
  scope_city_name: null,
  scope_city_id: null,
  permission: {
    containerRelease: false,
    containerReleaseExport: false,
    containerReleaseImport: false,
    containerGateIn: false,
    scope: 0,
  },
  id: null,
  disclaimers: false,
  disclaimersForce: null,
};

export const user = {
  state: initialState,
  reducers: {
    setLoggedUser: (state, payload) => {
      state.enterpriseName = payload.enterpriseName;
      state.mobile = payload.mobile;
      state.name = payload.name;
      state.permission = payload.permission;
      state.scope = payload.scope;
      state.id = payload.id;
      state.scope_city_name = payload.scope_city_name;
      state.scope_city_id = payload.scope_city_id;
      return state;
    },
    setDisclaimers: (state, payload) => {
      state.disclaimers = payload;
      return state;
    },
    setDisclaimersForce: (state, payload) => {
      state.disclaimersForce = payload;
      return state;
    },
  },
  effects: (dispatch) => ({
    async getLoggedUser() {
      return await api
        .get('/user/account/info')
        .then(({ data, error_code, error_msg }) => {
          if (error_code) return Promise.reject(error_msg);
          return data;
        })
        .then((data) => {
          return PlatformUtils.checkSystem(data);
        })
        .then((data) => {
          const convoyUserPermission = data.convoyPermissionOfAttributes;
          dispatch.user.setLoggedUser({
            enterpriseName: data.enter_name,
            mobile: data.mobile,
            name: data.name,
            scope: data.scope,
            scope_city_name: data.scope_city_name,
            scope_city_id: data.scope_city_id,
            permission: {
              containerRelease: get(
                convoyUserPermission,
                'permissionFX',
                false,
              ),
              containerReleaseExport: get(
                convoyUserPermission,
                'permissionFXExport',
                false,
              ),
              containerReleaseImport: get(
                convoyUserPermission,
                'permissionFXImport',
                false,
              ),
              containerGateIn: get(convoyUserPermission, 'permissionJG', false),
              scope: data.scope,
            },
            id: data.id,
          });
          return Promise.resolve(data);
        });
    },
    async getDisclaimers() {
      return await getDisclaimersStatus().then((res) => {
        dispatch.user.setDisclaimers(res.need_read === 1);
      });
    },
    async updateDisclaimers(read) {
      return dispatch.user.setDisclaimers(read);
    },
    async forceDisclaimers(force) {
      return dispatch.user.setDisclaimersForce(force);
    },
  }),
};
