import api, { xiangxinApi } from '@common/api';
/**
 * https://yapi.56hello.cn/project/664/interface/api/8748
 * @param {*} params
 * @returns
 */
export const allInformationApi = (params) => ({
  url: '/convoy/huoyunjie/content-list-all',
  params: params,
});

/**
 * https://yapi.56hello.cn/project/664/interface/api/8745
 * @param {*} params
 * @returns
 */
export const selfInformationApi = (params) => ({
  url: '/convoy/huoyunjie/content-list-self',
  params,
});

export const getHuoYunJieConfig = () => {
  return api.get('/huoyunjie/config');
};

/**
 * 资讯保存
 * @param {*} data
 * content_id	number	信息ID，为0表示创建
 * tag_id_str	string	标签ID，支持多个，用逗号隔开
 * content_text	string	信息text
 * contact_name	string	联系人姓名
 * contact_way	string	联系方式类型（微信|QQ|手机号等）
 * contact_value	string	联系方式
 * @returns
 */
export const updateHuoYunJieInfo = (data) => {
  return xiangxinApi.post('/convoy/huoyunjie/save', data);
};

export const xiajia = (data) => {
  return api.post('/convoy/huoyunjie/xiajia', data);
};

export const shangjia = (data) => {
  return api.post('/convoy/huoyunjie/shangjia', data);
};

// 联系发单统计埋点
export const statContactOpen = (data) => {
  return api.post('/convoy/huoyunjie/point/look-contact', data);
};

// 处理新增时的联系人联想
export const getRemoteContact = () => {
  return xiangxinApi.get('/convoy/huoyunjie/content-contact');
};

// 提落箱点（地址簿）
export const getProvinceList = () => {
  return api.get('/common/address/find/0');
};
// 提落箱点
export const pickLocationListApi = (params) => ({
  url: '/convoy/huoyunjie/v2/resource/icd',
  params,
});

export const addPickLocation = (data) => {
  return api.post('/convoy/huoyunjie/v2/resource/icd/new', data);
};

export const updatePickLocation = (id, data) => {
  return api.post(`/convoy/huoyunjie/v2/resource/icd/${id}`, data);
};

export const updatePickLocationStatus = (id, data) => {
  return api.post(`/convoy/huoyunjie/v2/resource/icd/${id}/op`, data);
};

// 门点
export const placeListApi = (params) => ({
  url: '/convoy/huoyunjie/v2/resource/place',
  params,
});

export const addPlace = (data) => {
  return xiangxinApi.post('/convoy/huoyunjie/v2/resource/place/new', data);
};

export const updatePlace = (id, data) => {
  return xiangxinApi.post(`/convoy/huoyunjie/v2/resource/place/${id}`, data);
};

export const updatePlaceStatus = (id, data) => {
  return xiangxinApi.post(`/convoy/huoyunjie/v2/resource/place/${id}/op`, data);
};

// 司机

export const driverListApi = (params) => ({
  url: '/convoy/huoyunjie/v2/resource/driver',
  params,
});

export const addDriver = (data) => {
  return xiangxinApi.post('/convoy/huoyunjie/v2/resource/driver/new', data);
};

export const updateDriver = (id, data) => {
  return xiangxinApi.post(`/convoy/huoyunjie/v2/resource/driver/${id}`, data);
};

export const updateDriverStatus = (id, data) => {
  return xiangxinApi.post(
    `/convoy/huoyunjie/v2/resource/driver/${id}/op`,
    data,
  );
};

// 费用名称
export const feeNameListApi = (params) => ({
  url: '/convoy/huoyunjie/v2/resource/feedata',
  params,
});

export const addFeeName = (data) => {
  return xiangxinApi.post('/convoy/huoyunjie/v2/resource/feedata/new', data);
};

export const updateFeeName = (id, data) => {
  return xiangxinApi.post(`/convoy/huoyunjie/v2/resource/feedata/${id}`, data);
};

export const updateFeeNameStatus = (id, data) => {
  return xiangxinApi.post(
    `/convoy/huoyunjie/v2/resource/feedata/${id}/op`,
    data,
  );
};

// 订单列表
export const outBoundListApi = (params) => ({
  url: '/convoy/huoyunjie/v2/order/my-list',
  params,
});
// 导出
export const downloadOrder = (params) =>
  xiangxinApi.get('/convoy/huoyunjie/v2/order/my-list/out_file', {
    params,
  });

// 新增订单
export const addOutBound = (data) => {
  return xiangxinApi.post('/convoy/huoyunjie/v2/order/new', data);
};

// 获取订单信息
export const getBoundInfo = (id) => {
  return xiangxinApi.get(`/convoy/huoyunjie/v2/order/${id}`);
};

// 更新订单基础信息
export const updateOutBound = (id, data) => {
  return xiangxinApi.post(`/convoy/huoyunjie/v2/order/${id}`, data);
};

// 更新订单提落箱信息
export const updateOutBoundPlace = (id, data) => {
  return xiangxinApi.post(`/convoy/huoyunjie/v2/order/${id}/edit_icd`, data);
};

// 更新订单装卸货时间
export const updateOutBoundTime = (id, data) => {
  return xiangxinApi.post(`/convoy/huoyunjie/v2/order/${id}/edit_place`, data);
};

// 操作订单
export const operatorOutBound = (id, data) => {
  return xiangxinApi.post(`/convoy/huoyunjie/v2/order/${id}/op`, data);
};

// 调价
export const updateOutBoundPrice = (id, data) => {
  return xiangxinApi.post(`/convoy/huoyunjie/v2/order/${id}/price`, data);
};

// 订单费用列表
export const outBoundFeeListApi = (id) => ({
  url: `/convoy/huoyunjie/v2/order/${id}/fee`,
});

// 订单取消记录
export const outBoundCancelListApi = (id) => ({
  url: `/convoy/huoyunjie/v2/order/${id}/line`,
});

// 市场订单列表
export const outBoundMarketListApi = (params) => ({
  url: '/convoy/huoyunjie/v2/order/market',
  params,
});

// 分派给车队的订单
export const outBoundSignListApi = (params) => ({
  url: '/convoy/huoyunjie/v2/order/shuyu',
  params,
});

// 派单给车队
export const signForConvoy = (data) => {
  return xiangxinApi.post('/convoy/huoyunjie/v2/dispatch/assign/convoy', data);
};

export const signForDriver = (data) => {
  return xiangxinApi.post('/convoy/huoyunjie/v2/dispatch/assign/driver', data);
};

export const deleteOrder = (id) => {
  return xiangxinApi.post(`/convoy/huoyunjie/v2/order/${id}/op_drop`);
};

export const cleanPic = (order_id_str) => {
  return xiangxinApi.post('/huoyunjie/v2/order/luge/clean_pic', {
    order_id_str,
  });
};

// 获取声明状态
export const getDisclaimersStatus = () => {
  return xiangxinApi.get('/convoy/huoyunjie/notice/read-info');
};

// 已读声明
export const readDisclaimers = () => {
  return xiangxinApi.post('/convoy/huoyunjie/notice/read');
};

/**
 * 加速卡
 * card_can_use 是否可用
 * card_default_select 默认选择
 */
export const getAccelerateCardRight = () => {
  return xiangxinApi.get('/convoy/huoyunjie/power');
};

export const billListApi = () => ({
  url: '/convoy/huoyunjie/v2/financial/bill-list',
  params: {
    pay_type: '2',
  },
});

export const billDetailApi = (params) => ({
  url: '/convoy/huoyunjie/v2/financial/bill/detail',
  params,
});

export const getValidBill = () => ({
  url: '/convoy/huoyunjie/v2/financial/order_list_when_bill_new',
});

export const createBill = (data) => {
  return xiangxinApi.post('/convoy/huoyunjie/v2/financial/bill_new', data);
};

export const applyForInvoice = (data) => {
  return xiangxinApi.post(
    '/convoy/huoyunjie/v2/financial/bill/shen_qing_kai_piao',
    data,
  );
};

// 获取手续费
export const getCommission = () => {
  return xiangxinApi.get('/convoy/huoyunjie/v2/order/fee/shouxu_config');
};

// 支付申请
export const applyPay = (data) =>
  xiangxinApi.post('/huoyunjie/v2/order/luge/apply_pay', data);

export const transformLuYun = (id) =>
  xiangxinApi.get(`/convoy/huoyunjie/v2/order/${id}/from_luyun`);
